
import { Vue, Options } from "vue-class-component";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ListPickupController } from "@/app/ui/controllers/ListPickupController";
import {
  CancelPickupApiRequest,
  ListPickupDetail,
  ListStt,
} from "@/domain/entities/ListPickupDetail";
import { ApiStateData } from "@/domain/entities/Api";
import {
  ErrorMessageEntities,
  ModalMessageEntities,
} from "@/domain/entities/MainApp";
import { CLIENT_ERROR } from "@/app/infrastructures/misc/Constants";
import copy from "copy-text-to-clipboard";
import { AccountController } from "@/app/ui/controllers/AccountController";
import CardAddress from "../../components/card-address/index.vue";

@Options({
  components: {
    CardAddress,
  },
})
export default class DetailPickup extends Vue {
  accountController = AccountController;
  controller = ListPickupController;
  apiDetailData = new ApiStateData();
  detailData = new ListPickupDetail();
  photoPreview = false;
  photo = "";
  showModalSuccess = false;
  showModalFailed = false;
  showModalConfirm = false;
  isLoadingSubmit = false;
  isLoadingSubmitCancelData = false;
  modalMessageEntities = new ModalMessageEntities();
  modalErrorMessageEntities = new ErrorMessageEntities();
  modalConfirmEntities = new ModalMessageEntities({
    image: "badge-confirmation-general",
    title: "Batalkan Pick Up?",
    message:
      "Permintaan Pick Up tidak akan diproses jika membatalkan Pick Up ini.",
  });
  info = false;
  status = "";
  wordingCopied = "";

  goBack() {
    this.$router.push("/pickup/list-pickup");
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get isAdminTransport() {
    return this.accountController.accountData.isAdminTransport;
  }

  get isPcaEmpty() {
    return this.detailData.isPcaEmpty;
  }

  async fetchDetail() {
    try {
      MainAppController.showLoading();
      this.apiDetailData.loading = true;
      const resp = await this.controller.getListPickupDetail(this.id);
      this.detailData = new ListPickupDetail(resp);
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
      MainAppController.closeLoading();
    }
  }

  getFormattedPickupDate(dateString: string) {
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  onButtonCancelPressed() {
    this.showModalConfirm = true;
  }

  async onSubmit(): Promise<void> {
    this.showModalFailed = false;
    this.showModalSuccess = false;
    this.showModalConfirm = false;

    const payload = new CancelPickupApiRequest({
      shipmentId: this.detailData.shipmentId,
    });

    try {
      MainAppController.showLoading();
      this.isLoadingSubmitCancelData = true;
      await this.controller.cancelPickup(payload);
      this.modalMessageEntities = {
        ...this.modalMessageEntities,
        image: "badge-success",
      };
      this.showModalSuccess = true;
    } catch (error) {
      const message = error.message;
      this.modalErrorMessageEntities = new ErrorMessageEntities({
        type: CLIENT_ERROR,
        title: `"${this.detailData.shipmentId}" gagal dibatalkan`,
        message:
          typeof message === "string"
            ? "Pembatalan Pick Up gagal. Cek & coba kembali."
            : message.id,
        onTryAgain: this.onSubmit,
        isFailed: true,
        buttonSuccessText: "Ulangi",
      });
      this.showModalFailed = true;
    } finally {
      this.isLoadingSubmit = false;
      MainAppController.closeLoading();
    }
  }

  get isSttEmpty() {
    return (
      this.detailData.listStt === null || this.detailData.listStt.length === 0
    );
  }

  onPreview(photo: string) {
    this.photo = photo;
    this.photoPreview = true;
  }

  get photoPreviews() {
    return this.detailData.pickupProof.map((item: any) => {
      return {
        image: item,
        url: item,
      };
    });
  }

  onCloseModalSucces(): void {
    this.showModalSuccess = false;
    this.$router.push("/pickup/list-pickup");
  }

  copyShipmentId() {
    if (this.detailData.shipmentId && this.detailData.shipmentId !== "") {
      this.setInfo("copied");
      this.wordingCopied = "Shipment ID successfully copied";
      copy(this.detailData.shipmentId);
    }
  }

  setInfo(status: string) {
    this.info = true;
    this.status = status;
  }

  closeInfo() {
    this.info = false;
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-nowrap",
        render: (_: ListStt, i: number) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${i +
            1}</span>`,
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: ListStt) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                  ${item.sttNo || "-"}
                </div>`;
        },
      },
      {
        name: this.$t("Jenis Pengiriman"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: ListStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${item.serviceCode}</span>`,
      },
      {
        name: this.$t("Tanggal booking"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: ListStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${this.getFormattedPickupDate(
            item.bookingDate
          )}</span>`,
      },
      {
        name: this.$t("Total koli"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: ListStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${item.totalKoli}</span>`,
      },
      {
        name: this.$t("Berat kotor"),
        styleHead: "w-40 text-left whitespace-nowrap",
        render: (item: ListStt) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${item.grossWeight} kg</span>`,
      },
    ];
  }

  pagination = {
    page: 1,
    limit: 100,
  };
}
