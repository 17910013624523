
import { ListPickupDetail } from "@/domain/entities/ListPickupDetail";
import { Vue, prop } from "vue-class-component";

class Props {
  data = prop<ListPickupDetail>({
    default: new ListPickupDetail()
  });
}


export default class CardAddress extends Vue.with(Props)  {

}
