import { configDates, configDay } from "@/app/infrastructures/misc/common-library/modules/Date";
import { PickupSchedule } from "@/domain/entities/PickupSchedule";

export const formatRepeatDays = (obj: PickupSchedule, isUsingTime = true) => {
    const splitDay = obj.pickupScheduleRepeatDays.split(",");
    const startDay = splitDay.length < 2 ? splitDay : splitDay.slice(0, -1);
    const lastDay = splitDay.slice(-1);
    const convertDay = `${startDay
        .map((key: any) => configDay(key))
        .join(", ")
        .toString()} ${splitDay.length > 1 ? `dan ${configDay(lastDay.toString().trim())}`: ""}`;
    return obj.pickupScheduleIntervalWeek === 0 || !obj.pickupScheduleRepeatDays.length
        ? "-"
        : `Hari ${convertDay} ${isUsingTime ? `${obj.pickupScheduleTime} ${configDates(obj.pickupScheduleCityTimezone).id}`:''}, setiap ${obj.pickupScheduleIntervalWeek} minggu sekali`;
}

export default formatRepeatDays;